import React, {useState, useEffect} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Typography,
  Stack,
  DialogContent,
  DialogContentText,
  Button,
  Box,
  TextField,
  Divider,
  FormControlLabel,
  Checkbox,
  Grid,
  MenuItem,
  Select,
  FormControl,
  InputLabel
} from '@mui/material'
import {styled} from '@mui/material/styles'

import {LoadingButton} from '@mui/lab'
import {Icon} from '@iconify/react'
import closeIcon from '@iconify/icons-eva/close-circle-outline'

import PropTypes from 'prop-types'

import {endShift_calculations} from 'utils/gigComputation'

const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root.Mui-disabled': {
    // backgroundColor: 'lightgray',
    color: 'red'
  }
})

export default function ConfirmEndShiftNotification({open, gig, onCommit, handleClose, loading}) {
  const {fees} = gig
  const [isLoading, setLoading] = useState(false)
  const [hoursLate, setHoursLate] = useState()
  const [workTime, setWorkTime] = useState()
  const [extension, setExtension] = useState()
  const [nightSurge, setNightSurge] = useState()

  const [isEdit, setEdit] = useState(false)
  const [isEnableEdit, setEnableEdit] = useState(false)
  const [isFinal, setIsFinal] = useState(false)
  const [touched, setTouched] = useState(false)
  const [checked, setChecked] = useState(false)

  const handleBlur = () => {
    setTouched(true) // Mark the field as touched on blur
  }

  const handleCommit = (value) => {
    let extensionData = extension ? extension : value.fees?.proposedExtensionHr

    if (Math.sign(workTime) <= 0) {
      return alert('Cannot set zero or negative work hours')
    } else if (Math.sign(value.fees?.proposedLateMin) < 0) {
      return alert('Cannot set negative late')
    } else if (Math.sign(extensionData) < 0) {
      return alert('Cannot set negative extension hours')
    } else if (Math.sign(value.fees?.proposedNightSurgeHr) < 0) {
      return alert('Cannot set negative nightSurge hours')
    }

    try {
      // timelate should always be in minites for calculation
      let data = {
        timeLate: hoursLate ? hoursLate : value.fees?.proposedLateMin,
        actualTime: workTime ? workTime : value.fees?.proposedWorkTime,
        actualExtension: extension ? extension : value.fees?.proposedExtensionHr,
        actualNightSurge: nightSurge ? nightSurge : value.fees?.proposedNightSurgeHr,
        ...value
      }

      if (data?.timeLate === null || data?.timeLate === undefined) {
        return alert('Total manhours is required')
      }

      handleCloseDialog()
      onCommit(data)
    } catch (error) {
      console.log(error)
    }
  }

  const formatDecimal = (value, type) => {
    if (!value) return
    if (type === 'hours') {
      setWorkTime(parseFloat(value).toFixed(2))
    }
    if (type === 'late') {
      setHoursLate(parseFloat(value).toFixed(2))
    }
    if (type === 'extension') {
      setExtension(parseFloat(value).toFixed(2))
    }
    if (type === 'nightSurge') {
      setNightSurge(parseFloat(value).toFixed(2))
    }
  }

  const handleTime = (value) => {
    const absoluteHours = Math.floor(Math.abs(value))
    const minutes = Math.floor((Math.abs(value) * 60) % 60)

    return `${absoluteHours} hours ${minutes} mins`
  }

  const checkProposedTime = (gig) => {
    if (gig?.fees?.proposedWorkTime?.length !== 0) {
      return parseFloat(gig?.fees?.proposedWorkTime).toFixed(2)
    }

    return gig?.hours
  }

  const checkProposedRate = (gig) => {
    if (gig?.fees?.jobsterTotal?.length !== 0) {
      return parseFloat(gig?.fees?.jobsterTotal).toFixed(2)
    }

    return gig?.fee
  }

  const handleEdit = () => {
    setEdit(!isEdit)
    setEnableEdit(!isEnableEdit)
  }

  const handleChange = (e) => {
    setChecked(e.target.checked)
  }

  const handleCloseDialog = () => {
    setChecked(false)
    setIsFinal(false)
  }
  console.log(gig, 'GIG')
  const handleCancelEdit = () => {
    setWorkTime(gig.hours)
    setExtension(gig.ex)
    setHoursLate('0.00')
    setNightSurge('0')
    const total = parseTime(parseFloat(gig.hours || 0))
    setTotalHours(total.hours)
    setTotalMinutes(total.minutes)
    setLateHours('')
    setLateMinutes('')
    setExtensionHours('')
    setExtensionMinutes('')
    setNightSurgeHours('')
    setNightSurgeMinutes('')
    setExpectedGigFee('')
    handleClose()
    setIsNotComplete(true)
  }
  const [totalHours, setTotalHours] = useState(0)
  const [totalMinutes, setTotalMinutes] = useState(0)
  const [lateHours, setLateHours] = useState(0)
  const [lateMinutes, setLateMinutes] = useState(0)
  const [extensionHours, setExtensionHours] = useState(0)
  const [extensionMinutes, setExtensionMinutes] = useState(0)
  const [nightSurgeHours, setNightSurgeHours] = useState(0)
  const [nightSurgeMinutes, setNightSurgeMinutes] = useState(0)
  const [expectedGigFee, setExpectedGigFee] = useState('')
  const [isNotComplete, setIsNotComplete] = useState(true)
  const [limit, setLimit] = useState(0)

  const hoursLimit = () => {
    if (fees) {
      return gig.hours < 8 ? 9 : parseInt(gig.hours) + 1
    }
    // return 24
    return 9
  }
  const parseTime = (time) => {
    const hours = Math.floor(time)
    const minutes = Math.round((time - hours) * 60)
    return {hours, minutes}
  }

  const isEmpty = () => {
    return (
      totalHours === '' ||
      totalMinutes === '' ||
      lateHours === '' ||
      lateMinutes === '' ||
      extensionHours === '' ||
      extensionMinutes === '' ||
      nightSurgeHours === '' ||
      nightSurgeMinutes === ''
    )
  }

  const handleHoursChange = (event, setter) => {
    setter(event.target.value)
  }

  const handleMinutesChange = (event, setter) => {
    setter(event.target.value)
  }

  const hoursOptions = Array.from({length: 24}, (_, i) => i)
  const minutesOptions = Array.from({length: 60}, (_, i) => i)
  const isRestricted = parseFloat(gig?.hours).toFixed(2) === workTime && totalMinutes === 0

  const calculateTotalMinutes = (hours, minutes) => {
    return hours * 60 + minutes
  }

  const calculateDecimalTime = (hours, minutes) => {
    return (hours + minutes / 60).toFixed(2)
  }

  const calculateLate = (hours, minutes) => {
    return hours * 60 + minutes // Convert hours to minutes and add minutes
  }

  useEffect(() => {
    // calculate expected gig fee
    if (gig) {
      setLimit(hoursLimit())
      const calculatedWorktime = calculateDecimalTime(totalHours, totalMinutes)
      const calculatedLate = calculateLate(lateHours, lateMinutes)
      const calculatedExtension = calculateDecimalTime(extensionHours, extensionMinutes)
      const calculatedNightSurge = calculateDecimalTime(nightSurgeHours, nightSurgeMinutes)

      setWorkTime(calculatedWorktime)
      setHoursLate(calculatedLate)
      setExtension(calculatedExtension)
      setNightSurge(calculatedNightSurge)

      const convertedLate = calculateTotalMinutes(lateHours, lateMinutes)
      console.log(isEmpty(), 'IS EMPTY')
      if (!isEmpty()) {
        setIsNotComplete(false)
      }

      const {jobsterFinal} = endShift_calculations(
        calculatedWorktime,
        gig?.fee,
        fees?.voluntaryFee,
        fees?.premiumFee,
        fees?.holidaySurge,
        convertedLate,
        calculatedExtension,
        calculatedNightSurge
      )
      setExpectedGigFee(jobsterFinal)
    }
  }, [
    totalHours,
    totalMinutes,
    lateHours,
    lateMinutes,
    extensionHours,
    extensionMinutes,
    nightSurgeHours,
    nightSurgeMinutes
  ])

  const handleCloseEdit = () => {
    setEdit(false)
    initateVariables()
  }

  const initateVariables = () => {
    const total = parseTime(parseFloat(fees?.proposedWorkTime || 0))
    setTotalHours(total.hours)
    setTotalMinutes(total.minutes)

    const late = parseTime(parseFloat(fees?.proposedLateMin || 0))
    setLateHours(late.hours)
    setLateMinutes(late.minutes)

    const extension = parseTime(parseFloat(fees?.proposedExtensionHr || 0))
    setExtensionHours(extension.hours)
    setExtensionMinutes(extension.minutes)

    const nightSurge = parseTime(parseFloat(fees?.proposedNightSurgeHr || 0))
    setNightSurgeHours(nightSurge.hours)
    setNightSurgeMinutes(nightSurge.minutes)
  }

  useEffect(() => {
    if (fees) {
      initateVariables()
    }
  }, [fees])

  console.log(workTime, hoursLate, extension, nightSurge)
  return (
    <div>
      <Dialog open={open ?? false} onClose={handleClose}>
        <Button sx={{ml: 'auto', p: 2}} onClick={handleClose}>
          <Icon icon={closeIcon} width={42} height={42} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{textAlign: 'center'}}>
          <Typography variant="h6" component="span" sx={{fontWeight: 'bold'}}>
            Reminder
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{textAlign: 'center'}}>
            <Typography variant="h4" component="span" sx={{textAlign: 'center', fontWeight: 'bold'}} color="#000">
              Confirm End-Shift
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{display: 'block', pb: 5, px: 5}}>
          <Typography variant="body1" sx={{mb: 3}}>
            The jobster has indicated a successful gig engagement with the following details below :
            {/* The jobster has indicated a successful gig engagement with a rate of <b>P {checkProposedRate(gig)}</b>/ hour
            and an actual number of hours/ minutes of <b>{checkProposedTime(gig)} hr/s.</b> Kindly ensure all tasks has
            been completely, accurately and efficiently done. Also, take note of your inventories, equipment and
            machines for the reporting of damages before confirmation a successful gig. Remember, confirming today’s gig
            as successful means that no untoward incidents or issues occurred during the gig-engagement.” If successful,
            please confirm. */}
          </Typography>
          {/* <Box>
            <TextField
              key="total-hours"
              label="Total manhours (ex 1.50 = 1 hr and 30 min)"
              sx={{
                width: '100%',
                mt: 2,
                '& .MuiInputBase-root.Mui-disabled': {
                  // backgroundColor: 'lightgray',
                  color: 'red', // Optional
                  '& fieldset': {
                    // Target the fieldset for border
                    borderColor: fees?.proposedWorkTime === '0.00' ? 'red' : 'gray'
                  }
                }
              }}
              defaultValue={parseFloat(fees?.proposedWorkTime).toFixed(2)}
              onChange={(e) => formatDecimal(e.target.value, 'hours')}
              onBlur={handleBlur}
              error={!fees?.proposedWorkTime && touched && !workTime}
              type="number"
              disabled={!isEdit}
            />
            <TextField
              key="late"
              label="Late (no. of minutes)"
              disabled={!isEdit}
              sx={{
                width: '100%',
                mt: 2,
                '& .MuiInputBase-root.Mui-disabled': {
                  // backgroundColor: 'lightgray',
                  color: 'red', // Optional
                  '& fieldset': {
                    borderColor: fees?.proposedLateMin === '0.00' ? 'red' : 'black'
                  }
                }
              }}
              defaultValue={parseFloat(fees?.proposedLateMin || 0.0).toFixed(2)}
              onChange={(e) => formatDecimal(e.target.value, 'late')}
              onBlur={handleBlur}
              type="number"
            />
            <TextField
              key="extension"
              label="Gig extension (no. of hours)"
              sx={{
                width: '100%',
                mt: 2,
                '& .MuiInputBase-root.Mui-disabled': {
                  // backgroundColor: 'lightgray',
                  color: 'red', // Optional
                  '& fieldset': {
                    borderColor: fees?.proposedExtensionHr === '0.00' ? 'red' : 'gray'
                  }
                }
              }}
              defaultValue={parseFloat(fees?.proposedExtensionHr).toFixed(2)}
              onChange={(e) => formatDecimal(e.target.value, 'extension')}
              onBlur={handleBlur}
              type="number"
              disabled={!isEdit}
            />
            <TextField
              key="night-surge"
              label="Night Surge (no. of hours)"
              sx={{
                width: '100%',
                mt: 2,
                mb: 2,
                '& .MuiInputBase-root.Mui-disabled': {
                  // backgroundColor: 'lightgray',
                  color: 'red', // Optional
                  '& fieldset': {
                    borderColor: fees?.proposedNightSurgeHr === '0.00' ? 'red' : 'gray'
                  }
                }
              }}
              defaultValue={parseFloat(fees?.proposedNightSurgeHr).toFixed(2)}
              onChange={(e) => formatDecimal(e.target.value, 'nightSurge')}
              onBlur={handleBlur}
              type="number"
              disabled={!isEdit}
            />
            {/* <Typography sx={{my: 2}} variant="caption">
              (ex 1.50 = 1 hour and 30 minutes)
            </Typography> */}
          {/* </Box> */}
          <Box>
            <Grid container direction="column" spacing={2} sx={{pl: '0 !important', mt: 4, mb: 2}}>
              {/* Total Manhours */}
              <Grid item container spacing={1} alignItems="center">
                <Grid item xs={4} sx={{pl: '0 !important'}}>
                  <Typography>Total Manhours:</Typography>
                </Grid>
                <Grid item xs={4} sx={{}}>
                  <FormControl fullWidth>
                    <InputLabel>(hours)</InputLabel>
                    <Select
                      label="Total manhours (hours)"
                      value={totalHours}
                      onChange={(e) => handleHoursChange(e, setTotalHours)}
                      disabled={!isEdit}
                      sx={{
                        width: '100%',
                        mt: 2,
                        '& .MuiInputBase-root': {
                          '& fieldset': {
                            borderColor: fees?.proposedWorkTime === '0.00' ? 'red' : 'gray'
                          }
                        }
                      }}
                    >
                      {[...Array(limit).keys()].map((hour) => (
                        <MenuItem key={hour} value={hour}>
                          {hour}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={{}}>
                  <FormControl fullWidth>
                    <InputLabel>(minutes)</InputLabel>
                    <Select
                      label="Total manhours (minutes)"
                      value={totalMinutes}
                      onChange={(e) => handleMinutesChange(e, setTotalMinutes)}
                      disabled={!isEdit}
                      sx={{
                        width: '100%',
                        mt: 2,
                        '& .MuiInputBase-root.Mui-disabled': {
                          '& fieldset': {
                            borderColor: fees?.proposedWorkTime === '0.00' ? 'red' : 'gray'
                          }
                        }
                      }}
                    >
                      {isRestricted ? (
                        <MenuItem key={0} value={0}>
                          0
                        </MenuItem>
                      ) : (
                        [0, 30].map((minute) => (
                          <MenuItem key={minute} value={minute}>
                            {minute}
                          </MenuItem>
                        ))
                      )}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Late */}
              <Grid item container spacing={1} alignItems="center">
                <Grid item xs={4} sx={{pl: '0 !important'}}>
                  <Typography>Late:</Typography>
                </Grid>
                <Grid item xs={4} sx={{}}>
                  <FormControl fullWidth>
                    <InputLabel>(hours)</InputLabel>
                    <Select
                      label="Late (hours)"
                      value={lateHours}
                      onChange={(e) => handleHoursChange(e, setLateHours)}
                      disabled={!isEdit}
                      sx={{
                        width: '100%',
                        mt: 2,
                        '& .MuiInputBase-root.Mui-disabled': {
                          '& fieldset': {
                            borderColor: fees?.proposedLateMin === '0.00' ? 'red' : 'gray'
                          }
                        }
                      }}
                    >
                      {[...Array(24).keys()].map((hour) => (
                        <MenuItem key={hour} value={hour}>
                          {hour}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={{}}>
                  <FormControl fullWidth>
                    <InputLabel>(minutes)</InputLabel>
                    <Select
                      label="Late (minutes)"
                      value={lateMinutes}
                      onChange={(e) => handleMinutesChange(e, setLateMinutes)}
                      disabled={!isEdit}
                      sx={{
                        width: '100%',
                        mt: 2,
                        '& .MuiInputBase-root.Mui-disabled': {
                          '& fieldset': {
                            borderColor: fees?.proposedLateMin === '0.00' ? 'red' : 'gray'
                          }
                        }
                      }}
                    >
                      {[...Array(60).keys()].map((minute) => (
                        <MenuItem key={minute} value={minute}>
                          {minute}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Gig extension */}
              <Grid item container spacing={1} alignItems="center">
                <Grid item xs={4} sx={{pl: '0 !important'}}>
                  <Typography>Gig extension:</Typography>
                </Grid>
                <Grid item xs={4} sx={{}}>
                  <FormControl fullWidth>
                    <InputLabel>(hours)</InputLabel>
                    <Select
                      label="Gig extension (hours)"
                      value={extensionHours}
                      onChange={(e) => handleHoursChange(e, setExtensionHours)}
                      disabled={!isEdit}
                      sx={{
                        width: '100%',
                        mt: 2,
                        '& .MuiInputBase-root.Mui-disabled': {
                          '& fieldset': {
                            borderColor: fees?.proposedExtensionHr === '0.00' ? 'red' : 'gray'
                          }
                        }
                      }}
                    >
                      {[...Array(24).keys()].map((hour) => (
                        <MenuItem key={hour} value={hour}>
                          {hour}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} sx={{}}>
                  <FormControl fullWidth>
                    <InputLabel>(minutes)</InputLabel>
                    <Select
                      fullWidth
                      label="Gig extension (minutes)"
                      value={extensionMinutes}
                      onChange={(e) => handleMinutesChange(e, setExtensionMinutes)}
                      disabled={!isEdit}
                      sx={{
                        width: '100%',
                        mt: 2,
                        '& .MuiInputBase-root.Mui-disabled': {
                          '& fieldset': {
                            borderColor: fees?.proposedExtensionHr === '0.00' ? 'red' : 'gray'
                          }
                        }
                      }}
                    >
                      {[0, 30].map((minute) => (
                        <MenuItem key={minute} value={minute}>
                          {minute}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Night Surge */}
              <Grid item container spacing={1} alignItems="center">
                <Grid item xs={4} sx={{pl: '0 !important'}}>
                  <Typography>Night Surge:</Typography>
                </Grid>
                <Grid item xs={4} sx={{}}>
                  <FormControl fullWidth>
                    <InputLabel>(hours)</InputLabel>
                    <Select
                      label="Night Surge (hours)"
                      value={nightSurgeHours}
                      onChange={(e) => handleHoursChange(e, setNightSurgeHours)}
                      disabled={!isEdit}
                      sx={{
                        width: '100%',
                        mt: 2,
                        '& .MuiInputBase-root.Mui-disabled': {
                          '& fieldset': {
                            borderColor: fees?.proposedNightSurgeHr === '0.00' ? 'red' : 'gray'
                          }
                        }
                      }}
                    >
                      {[...Array(9).keys()].map((hour) => (
                        <MenuItem key={hour} value={hour}>
                          {hour}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl fullWidth sx={{mt: 2}}>
                    <InputLabel>(minutes)</InputLabel>
                    <Select
                      label="Night Surge (minutes)"
                      value={nightSurgeMinutes}
                      onChange={(e) => handleMinutesChange(e, setNightSurgeMinutes)}
                      disabled={!isEdit}
                      sx={{
                        width: '100%',
                        mt: 2,
                        mb: 2,
                        '& .MuiInputBase-root.Mui-disabled': {
                          '& fieldset': {
                            borderColor: fees?.proposedNightSurgeHr === '0.00' ? 'red' : 'gray'
                          }
                        }
                      }}
                    >
                      {[0].map((minute) => (
                        <MenuItem key={minute} value={minute}>
                          {minute}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          <Stack>
            <Button
              color="primary"
              variant="contained"
              size="medium"
              onClick={() => setIsFinal(true)}
              disabled={isNotComplete}
              loading={loading}
            >
              {!isEdit ? 'No Changes, Confirm End Shift' : 'Submit and Confirm End Shift'}
            </Button>

            {!isEdit ? (
              <LoadingButton
                onClick={() => {
                  setEnableEdit(!isEnableEdit)
                }}
                variant="outlined"
                size="medium"
                color="error"
                sx={{mt: 2}}
              >
                Make an adjustment
              </LoadingButton>
            ) : (
              <Button onClick={handleCloseEdit} variant="outlined" size="medium" color="error" sx={{mt: 2}}>
                Cancel
              </Button>
            )}
          </Stack>
        </DialogActions>
      </Dialog>
      <Dialog open={isEnableEdit}>
        <Button sx={{ml: 'auto', pt: 2}} onClick={() => setEnableEdit(false)}>
          <Icon icon={closeIcon} width={32} height={32} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{textAlign: 'center'}}>
          <Typography variant="h4" sx={{maxWidth: '75%', mx: 'auto'}}>
            Do you want to edit the gig details?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{display: 'block', pb: 5, px: 3}}>
          <Typography variant="h6" sx={{mb: 6, fontWeight: 400, textAlign: 'center', mx: 'auto'}}>
            Kindly coordinate to the Jobster the gig details you want to edit. Please ensure the jobster will
            acknowledge the changes
          </Typography>

          <Stack sx={{my: 2}}>
            <LoadingButton
              color="primary"
              size="large"
              variant="contained"
              onClick={() => {
                setEdit(true)
                setEnableEdit(false)
              }}
              loading={loading}
              disabled={loading}
              sx={{textTransform: 'initial !important'}}
            >
              Proceed to edit
            </LoadingButton>
            <LoadingButton
              onClick={() => setEnableEdit(!isEnableEdit)}
              size="large"
              variant="outlined"
              color="error"
              sx={{mt: 2}}
            >
              Cancel
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>

      {/* Final confirm dialog */}
      <Dialog open={isFinal}>
        <Button sx={{ml: 'auto', pt: 2}} onClick={() => handleCloseDialog()}>
          <Icon icon={closeIcon} width={32} height={32} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{textAlign: 'center'}}>
          <Typography variant="h4" sx={{maxWidth: '100%', mx: 'auto'}}>
            Do you confirm to end this shift?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{display: 'block', pb: 5, px: 3}}>
          <Typography variant="body2" sx={{mb: 6, fontWeight: 400, textAlign: 'justify', mx: 'auto'}}>
            {/* The jobster indicated P {checkProposedRate(gig)}/ hour for {checkProposedTime(gig)} hr/s with{' '}
            <b style={{color: hoursLate ? '#0d7bc9' : 'inherit'}}>{hoursLate ? handleTime(hoursLate) : 'no'}</b> late.
            Once you processed this you won't be able to undo your changes. */}
            {/* Kindly ensure all tasks has been completely, accurately and efficiently done. Also, take note of your
            inventories, equipment and machines for the reporting of damages before confirmation a successful gig.
            Remember, confirming today’s gig as successful means that no untoward incidents or issues occurred during
            the gig-engagement.” If successful, please confirm. */}
            Ensure all tasks are completed accurately and efficiently. Check inventories, equipment, and machines for
            damages before confirming a successful gig. <strong>Confirm Only if everything is in order. </strong>
            <Divider sx={{mb: 1, mt: 2, fontWeight: 400, backgroundColor: 'rgba(0, 0, 0, 0.87)'}} />
            Confirmed time records
            <strong> cannot be adjusted</strong>, and gig fees will be released after confirmation. Please ensure that
            all details are correct.
          </Typography>
          <Box sx={{display: 'inline', width: {xs: '50%', sm: '33%', lg: '33%'}}}>
            <FormControlLabel
              sx={{marginTop: '0 !important'}}
              control={
                <Checkbox
                  id="skills"
                  color="primary"
                  checked={checked}
                  onChange={(e) => handleChange(e)}
                  // value={checked}
                />
              }
              label={
                <Typography variant="body2" align="left" sx={{color: 'text.secondary', marginTop: '0 !important'}}>
                  I confirm that the time records are correct
                </Typography>
              }
            />
          </Box>
          <Stack sx={{my: 2}}>
            <LoadingButton
              color="primary"
              size="medium"
              variant="contained"
              onClick={() => handleCommit(gig)}
              loading={loading}
              disabled={!checked}
              sx={{textTransform: 'initial !important'}}
            >
              Yes, I have approved the gig rate and work hours.
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                setIsFinal(false)
                setChecked(false)
              }}
              size="medium"
              variant="outlined"
              color="error"
              sx={{mt: 2}}
            >
              Cancel
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  )
}

ConfirmEndShiftNotification.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  gig: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onCommit: PropTypes.func,
  handleClose: PropTypes.func,
  onReject: PropTypes.func,
  loading: PropTypes.bool
}
