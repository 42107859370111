import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import {styled} from '@mui/system'

const StyledDialogTitle = styled(DialogTitle)({
  position: 'relative'
})

export default function NoticeDialog({open, handleClose}) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Notice to Clients</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <br />
          <Typography variant="body2" sx={{fontStyle: 'italic', fontSize: '0.875rem'}}>
            Pakitiyak na ma-kumpirma ang pagtatapos ng shift ng jobster sa loob ng 10 minuto. Kung hindi makumpirma ang
            pagtatapos ng shift sa loob ng oras na ito, ang kliyente ang mamahala sa pag-aayos ng bayad sa gig.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
NoticeDialog.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  handleClose: PropTypes.func
}
