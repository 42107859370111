import React, {useEffect, useState, createContext, useContext} from 'react'
import Pusher from 'pusher-js'
import {useLocation} from '@reach/router'

import {last} from 'lodash'
import PropTypes from 'prop-types'

import user_api from 'libs/endpoints/users'
import {useAuth} from './AuthContext'
import {unauthenticatedPages} from './SessionContext'
import {useFirebase} from 'contexts/FirebaseContext'
import storage from 'utils/storage'

import config from 'utils/config'

NotificationsProvider.propTypes = {
  children: PropTypes.node
}

const NotificationsContext = createContext({})

export function NotificationsProvider({children}) {
  const {currentUser, sessionUser} = useAuth()
  const [notification, setNotifications] = useState(0)
  const [notifData, setNotifData] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [isLoadingUpdate, setLoadingUpdate] = useState(false)
  const [currentTotalItems, setCurrentTotalItems] = useState(0);
  const location = useLocation()
  const {fcmNotification} = useFirebase()

  const pusher = new Pusher(config.pusher.key, {
    cluster: config.pusher.cluster,
    encrypted: true
  })
  const channel = pusher.subscribe('notifications')

  const mergeData = (existingData, newData) => {
    
    const existingIds = new Set(existingData.data.map(item => item._id)); // Assuming each notification has a unique `id`
    const mergedData = {
      data: [...existingData.data, ...newData.data.filter(item => !existingIds.has(item._id))],
      totalItems: newData.totalItems,
    };
    return mergedData;
  };

  const processNotificationsData = (data, totalItems) => {
    // Count the number of notifications and add it to the result
    if (totalItems){
      return {
        data: data, // The actual notification data
        notifCount: data.length, 
        totalItems: totalItems 
      };
    } else {
      return {
        data: data.data, // The actual notification data
        notifCount: data.data.length, 
        totalItems: data.totalItems 
      };
    }
  };

  const load = async () => {
    setLoadingUpdate(true)
    try {
      const current_page = last(location.pathname.replace('/', '').split('/'))
      if (unauthenticatedPages.includes(current_page)) {
        return
      }
  
      await sessionUser()
  
      if (!currentUser) {
        return
      }
  
      let result
      if (currentUser.accountType === 1) {
        result = await user_api.get_user_notifications_client(currentUser._id, 1, 10)
      } else {
        result = await user_api.get_user_notifications(currentUser._id, 1, 10)
      }
      const isDemoAccount =
        currentUser.email === ('demo_jobster@starjobs.com.ph' || 'demo_client@starjobs.com.ph') ? true : false
  
      if (!result.ok) return
  
      const {data, totalItems} = result.data
      if (data.length === 0) return
      const checkLocalStorageNotif = storage.getNotifDoc()
      if(!checkLocalStorageNotif) {
        let unread = data.filter((obj) => obj.isRead === false)
        if (isDemoAccount === true) {
          unread = ''
        }
        storage.storeNotifDoc(processNotificationsData(data, totalItems))
        setNotifications(unread.length)
        setCurrentTotalItems(totalItems)
        setNotifData(data)
        setLoading(false)
        return
      }
      const mergedData = mergeData(JSON.parse(storage.getNotifDoc()), result.data);
  
      let unread = mergedData.data.filter((obj) => obj.isRead === false)
      if (isDemoAccount === true) {
        unread = ''
      }
      storage.storeNotifDoc(processNotificationsData(mergedData.data, totalItems));
      setNotifications(unread.length)
      setNotifData(mergedData.data)
    } catch (e) {
       console.log(e)
    } finally {
      setLoadingUpdate(false)
    }
  }

  const loadSocketConnection = () => {
    channel.bind('new_notification', () => {
      load()
    })
    channel.bind('notify_gig', () => {
      load()
    })
  }

  useEffect(() => {
    loadSocketConnection()
  }, [])

  useEffect(() => {
    load()
    // eslint-disable-next-line
  }, [fcmNotification, location.pathname])

  return (
    <NotificationsContext.Provider value={{notifData, setNotifData, isLoading, setLoading, isLoadingUpdate, currentTotalItems, setCurrentTotalItems, pusher, channel, load}}>
      {children}
    </NotificationsContext.Provider>
  )
}

export const useNotifications = () => {
  return useContext(NotificationsContext)
}
