import React from 'react'
import PropTypes from 'prop-types'
// material
import {Box} from '@mui/material'
// import BrandLogo from 'assets/static/favicon/starjobs-blue-black-outline.webp'
import {StaticImage} from 'gatsby-plugin-image'

Logo.propTypes = {
  sx: PropTypes.object
}

export default function Logo({sx}) {
  return (
    <Box sx={{width: 120, height: 120, objectFit: 'contain', ...sx}}>
      <StaticImage
        src="../assets/static/favicon/starjobs-blue-black-outline.webp"
        alt="Brand Logo Outline"
        placeholder="blurred"
        layout="fullWidth"
      />
    </Box>
  )
}
