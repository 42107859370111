import {useContext, useEffect, useState, createContext} from 'react'
import PropTypes from 'prop-types'
import {initializeApp} from 'firebase/app'
import {getMessaging, getToken, onMessage, deleteToken} from 'firebase/messaging'
import config from 'utils/config'
import storage from 'utils/storage'
FirebaseProvider.propTypes = {
  children: PropTypes.node
}

const FirebaseContext = createContext({})

export function FirebaseProvider({children}) {
  const [isInitialized, setIsInitialized] = useState(false)
  const [fcmNotification, setFcmNotification] = useState(null)
  const user = JSON.parse(storage.getUser())

  // If we have a window and the authToken already exists in localstorage then initialize the authToken value otherwise null.
  const [authToken, setAuthToken] = useState(
    typeof window === 'object' ? window.localStorage.getItem('authToken') : null
  )
  // If firebase has not been initialized then initialize it.

  // Initialize Firebase
  const firebase = initializeApp(config.firebase)
  let messaging = null

  if (!window.ReactNativeWebView?.postMessage) {
  // Initialize Firebase Cloud Messaging and get a reference to the service
   messaging = getMessaging(firebase)
  }

  // A method for setting the authToken in state and local storage.
  const onSetAuthToken = (token) => {
    setAuthToken(token)
    localStorage.setItem('authToken', token)
  }

  // If we have the window object and there is no authToken then try to get the authToken from local storage.
  useEffect(() => {
    if (typeof window === 'object' && !authToken) {
      const token = window.localStorage.getItem('authToken')

      if (token) {
        onSetAuthToken(token)
      }
    }
  }, [authToken])

  const getFcmToken = async () => {
    try {
      // Check if the browser is Safari
        const currentToken = await getToken(messaging, {vapidKey: config.browser.vapid_key})
        if (currentToken) {
          console.log('FCM Token:', currentToken)
          return currentToken
        } else {
          console.log('No registration token available. Request permission to generate one.')
        }
    } catch (err) {
      console.error('An error occurred while retrieving token.', err)
    }
    return
  }

  const deleteFirebaseToken = async (fcm) => {
    try {
      if (fcm) {
        await deleteToken(messaging, {token: fcm})
        console.log('Token deleted successfully')
      } else {
        console.log('No registration token available to delete.')
      }
    } catch (err) {
      console.error('An error occurred while deleting token. ', err)
    }
  }

  const onMessageListener = new Promise((resolve, reject) => {
    if (!window.ReactNativeWebView?.postMessage) {
      onMessage(messaging, (payload) => {
        resolve(payload)
        setFcmNotification(payload)
        const { body } = payload.notification;
        if (user.accountType === 1) {
          if (body.trim() === "Applicant has sent a gig request") {
            window.location.href = '/client/gig/create?tab=3'; // Redirect to a specific page
          } else if (body.trim() === "The jobster has arrived.") {
            window.location.href = '/client/gig/create?tab=1'; // Redirect to a specific page
          } else if (body.trim() === "The jobster have Ended the shift") {
            window.location.href = '/client/gig/create?tab=1'; // Redirect to a specific page
          }
        } else if (user.accountType === 0) {
          if (body.trim() === "Congratulations, your gig application has been accepted.") {
            window.location.href = '/freelancer/dashboard?tab=2'; // Redirect to a specific page
          } else if (body.trim() === "You will receive your gig fee in the next three (3) days. Thank you for using Starjobs.") {
            window.location.href = '/freelancer/dashboard?tab=4'; // Redirect to a specific page
          }
        }
      })
    }
  })

  return (
    <FirebaseContext.Provider
      value={{
        authToken,
        firebase,
        isInitialized,
        fcmNotification,
        onSetAuthToken,
        getFcmToken,
        deleteFirebaseToken,
        onMessageListener
      }}
    >
      {children}
    </FirebaseContext.Provider>
  )
}

export const useFirebase = () => {
  return useContext(FirebaseContext)
}
