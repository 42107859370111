import request from 'utils/header'
import requestBackup from 'utils/headerbackup'
import storage from 'utils/storage'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Asia/Manila')

const get_user = () => request.get(`/user`)

const get_users = (_id) => request.get(`/store/users/${_id}`)

const get_users_archived = (_id) => request.get(`/store/users/archive/${_id}`)

const post_store_register = (_data) => request.post(`/store/register`, _data)

const post_employee_register = (_data) => request.post(`/employee/register`, _data)

const patch_store_onboard = (_data, _id) => request.patch(`/user/store/${_id}`, _data)

const get_user_status = (_id) => request.get(`/user/status/${_id}`)

const post_user_status = (_status, _location, _id) => {
  const date = dayjs().tz('Asia/Manila').utc().format()
  return request.post(`/user/time/${_id}`, {status: _status, location: _location, logdate: date})
}

const patch_user_onboard = (_data, _id) => request.patch(`/user/${_id}`, _data)

const get_user_records = (_id) => request.get(`/user/records/${_id}`)

const get_user_profile = async () => {
  const current_user = await storage.getUser()
  if (!current_user) return false
  const {_id, email} = JSON.parse(current_user)
  return request.get(`/accounts/${_id}`).then((result) => {
    return {
      ok: true,
      data: {...result.data[0], email: email},
      ratings: {...result.data.ratings},
      comments: {...result.data.rateComments}
    }
  })
}

const get_user_profile_client = async (client_id) => request.get(`/clients/${client_id}`)

const get_user_edit_profile_client = async (client_id) => request.get(`/clients/edit/${client_id}`)

const get_user_profile_freelancer = async (freelancer_id) => request.get(`/applicant/details/${freelancer_id}`)

const get_user_notifications = async (_id, page, limit) => request.get(`/notifications?page=${page}&limit=${limit}`)

const get_user_notifications_client = async (_id, page, limit) => request.get(`/notifications?page=${page}&limit=${limit}`)

const get_user_notifications_details = async (_id) => request.get(`/notifications_v2/details/${_id}`)

const patch_user_notification_read = async (_id, uid) =>
  request.patch(`/notifications/${_id}`, {uid: uid, isRead: true})

const put_user_notification_read = async (_id, notifId) => request.put(`/notifications/${notifId}/${_id}`)

const patch_user_password = async (_id, form_data) => request.patch(`/user/change/${_id}`, form_data)

const get_user_list = () => request.get(`/applicant/list`)

const post_user_list_search = (formData) => request.post(`/applicant/list/search`, formData)

const get_user_activity = async (_id) => request.get(`/activity/${_id}`)

const get_user_activity_client = async (_id) => request.get(`/activity/client/${_id}`)

const get_request_activity_client = async (formData, _id) => request.post(`/activity/request/client/${_id}`, formData)

const patch_user_account = async (form_data) => request.patch('/user/update/account', form_data)

const patch_delete_user_account = async (_id) => request.patch(`/user/deleteAccount/${_id}`)
const patch_user_name = async (form_data, _id) => request.patch(`/user/updateName/${_id}`, form_data)

const patch_read_all_notifications = async () => request.patch('/user/notifications/read/all')

const test_notifications = (_id) => request.post(`/users/testNotification/${_id}`)

const get_active_fcm_token = async (_id) => request.get(`/users/activeFCMToken/${_id}`)

const subscribe_fcm_token = async (_id, fcmToken, device) =>
  request.post(`/users/fcm/register/${_id}`, {fcmToken, device})

const get_user_with_notif = async (_id) => request.get(`/users/getUserWithNotif/${_id}`)

const deleteDeviceFcmToken = async (_id, fcmToken) => {
  return await request.delete(`/users/fcm/unregister/${_id}`, {
    fcmToken: fcmToken
  })
}
const patch_user_gcash = async (userId, form_data) => request.patch(`/user/patchUserGCash/${userId}`, form_data)
const get_jobster_banners = async () => request.get(`/users/jobsterBanner/list`)
const get_client_banners = async () => request.get(`/users/clientBanner/list`)

const _expObject = {
  get_user,
  get_users,
  get_users_archived,
  post_store_register,
  post_employee_register,
  patch_store_onboard,
  get_user_status,
  post_user_status,
  patch_user_onboard,
  get_user_list,
  post_user_list_search,
  get_user_records,
  get_user_profile,
  get_user_profile_client,
  get_user_profile_freelancer,
  get_user_notifications,
  get_user_notifications_details,
  get_user_notifications_client,
  patch_user_notification_read,
  put_user_notification_read,
  patch_user_password,
  get_user_activity,
  get_user_activity_client,
  get_request_activity_client,
  get_user_edit_profile_client,
  patch_user_account,
  patch_delete_user_account,
  patch_read_all_notifications,
  patch_user_name,
  test_notifications,
  get_active_fcm_token,
  subscribe_fcm_token,
  get_user_with_notif,
  deleteDeviceFcmToken,
  patch_user_gcash,
  get_jobster_banners,
  get_client_banners
}

export default _expObject
