// contexts/GigsContext.js

import React, {createContext, useEffect, useState} from 'react'
import moment from 'moment'
//api
import user_api from 'libs/endpoints/users'
import PropTypes from 'prop-types'

const JobsterContext = createContext()

const JobsterProvider = ({children}) => {
  const [allUsers, setAllUsers] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [totalUsers, setTotalUsers] = useState(1)

  useEffect(() => {
    const loadGigs = async () => {
      setLoading(true)
      try {
        const formData = {
          category: '', // Replace with your category variable
          searchTerm: '',
          skip: '',
          sort: ''
        }
        // const result = await user_api.post_user_list_search(formData)
        const result = await user_api.get_user_list()
        console.log(result, 'context rResult')
        if (!result.ok) {
          return
        }
        // const {data} = result
        // if (!data || data.length === 0) {
        //   setAllUsers([])
        //   return
        // }
        // data.sort((a, b) => (moment(a.date + ' ' + a.time) > moment(b.date + ' ' + b.time) ? 1 : -1))
        // const filteredData = data.filter((obj) => (moment(obj.from).isValid() ? obj : ''))
        const {freelancers: newUsers, totalFreelancers} = result.data

        setAllUsers(result.data)
        setTotalUsers(totalFreelancers)
        // setLoading(false)
      } catch (error) {
        console.error('Error fetching users:', error)
      } finally {
        setLoading(false)
      }
    }
    loadGigs()
  }, [])

  return (
    <JobsterContext.Provider value={{allUsers, setAllUsers, totalUsers, setTotalUsers, isLoading, setLoading}}>
      {children}
    </JobsterContext.Provider>
  )
}

JobsterProvider.propTypes = {
  children: PropTypes.node
}
export {JobsterProvider, JobsterContext}
