import React from 'react'
import {Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography} from '@mui/material'

const UpdatePopup = ({open}) => {
  if (!open) return null

  const handleReload = () => {
    window.location.reload(true)
  }

  return (
    <Dialog
      open={open}
      onClose={() => {}}
      aria-labelledby="update-dialog-title"
      aria-describedby="update-dialog-description"
      PaperProps={{
        style: {
          position: 'absolute',
          overflow: 'hidden'
        }
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="update-dialog-title">Starjobs has been updated.</DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          A new version is available. Please restart/reload the app to get the latest updates. You may need to close and
          reopen the app from your recent apps list.
        </Typography>
      </DialogContent>
      {/* <DialogActions>
        <Button variant="contained" color="primary" onClick={onConfirm}>
          Reload
        </Button>
      </DialogActions> */}
    </Dialog>
  )
}

export default UpdatePopup
