import {
  Dialog,
  DialogTitle,
  DialogActions,
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Stack,
  Button,
  TextField,
  Divider,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material'
import {LoadingButton} from '@mui/lab'
import PropTypes from 'prop-types'
import React, {useState, useEffect} from 'react'

// icons
import {Icon} from '@iconify/react'
import closeIcon from '@iconify/icons-eva/close-circle-outline'

import {styled} from '@mui/material/styles'
const CustomTextField = styled(TextField)({
  '& .MuiInputBase-root.Mui-disabled': {
    // backgroundColor: 'lightgray',
    color: 'red'
  }
})

import {endShift_calculations} from 'utils/gigComputation'

const EndShiftNotification = ({open, gig, onCommit, handleClose, loading}) => {
  const {fees} = gig
  // const [workTime, setWorkTime] = useState(gig?.hours)
  const [workTime, setWorkTime] = useState()
  const [late, setLate] = useState('')
  const [extension, setExtension] = useState('')
  const [nightSurge, setNightSurge] = useState('')
  const [checked, setChecked] = useState(false)

  const [rate, setRate] = useState(undefined)
  const [isFinal, setIsFinal] = useState(false)
  const [limit, setLimit] = useState(0)

  const hoursLimit = () => {
    if (fees) {
      return gig.hours < 8 ? 9 : parseInt(gig.hours) + 1
    }
    // return 24
    return 9
  }

  const handleCommit = (value) => {
    setChecked(false)
    if (value.hours === null || value.hours === undefined) {
      return alert('Kindly set the number of worked hours')
    }

    if (Math.sign(workTime) <= 0) {
      return alert('Cannot set zero or negative work hours')
    } else if (Math.sign(late) < 0) {
      return alert('Cannot set negative late')
    } else if (Math.sign(extension) < 0) {
      return alert('Cannot set negative extension')
    } else if (Math.sign(nightSurge) < 0) {
      return alert('Cannot set negative nightSurge')
    }

    try {
      let data = {
        new_status: 'End-Shift',
        actualTime: workTime ?? value.hours,
        actualLate: late ? late : '0.00',
        actualExtension: extension ?? '0.00',
        actualNightSurge: nightSurge ?? fees?.proposedNightSurgeHr,
        ...value
      }

      onCommit(data)
    } catch (error) {
      console.log(error)
    }
  }

  const formatDecimal = (value, type) => {
    if (!value) return
    if (type === 'hours') {
      setWorkTime(parseFloat(value).toFixed(2))
    }
    if (type === 'late') {
      setLate(parseFloat(value).toFixed(2))
    }
    if (type === 'extension') {
      setExtension(parseFloat(value).toFixed(2))
    }
    if (type === 'nightSurge') {
      setNightSurge(parseFloat(value).toFixed(2))
    }
  }

  const handleTime = (value) => {
    const absoluteHours = Math.floor(Math.abs(value))
    const minutes = Math.floor((Math.abs(value) * 60) % 60)

    return (
      <>
        {' about your new worktime '}
        <br></br>
        <b style={{color: '#0d7bc9'}}>{`${absoluteHours} hours ${minutes} mins`}</b>
        <br></br>
      </>
    )
  }

  const handleChange = (e) => {
    setChecked(e.target.checked)
  }

  const handleCloseDialog = () => {
    setWorkTime(gig.hours)
    setExtension('0.00')
    setLate('0.00')
    setNightSurge('0')
    const total = parseTime(parseFloat(gig.hours || 0))
    setTotalHours(total.hours)
    setTotalMinutes(total.minutes)
    setLateHours('')
    setLateMinutes('')
    setExtensionHours('')
    setExtensionMinutes('')
    setNightSurgeHours('')
    setNightSurgeMinutes('')
    setExpectedGigFee('')
    handleClose()
    setIsNotComplete(true)
  }

  // Dialog variables
  const [totalHours, setTotalHours] = useState('')
  const [totalMinutes, setTotalMinutes] = useState('')
  const [lateHours, setLateHours] = useState('')
  const [lateMinutes, setLateMinutes] = useState('')
  const [extensionHours, setExtensionHours] = useState('')
  const [extensionMinutes, setExtensionMinutes] = useState('')
  const [nightSurgeHours, setNightSurgeHours] = useState('')
  const [nightSurgeMinutes, setNightSurgeMinutes] = useState('')
  const [expectedGigFee, setExpectedGigFee] = useState('')
  const [isNotComplete, setIsNotComplete] = useState(true)

  const parseTime = (time) => {
    const hours = Math.floor(time)
    const minutes = Math.round((time - hours) * 60)
    return {hours, minutes}
  }

  const isEmpty = () => {
    return (
      totalHours === '' ||
      totalMinutes === '' ||
      lateHours === '' ||
      lateMinutes === '' ||
      extensionHours === '' ||
      extensionMinutes === '' ||
      nightSurgeHours === '' ||
      nightSurgeMinutes === ''
    )
  }

  const handleHoursChange = (event, setter) => {
    setter(event.target.value)
  }

  const handleMinutesChange = (event, setter) => {
    setter(event.target.value)
  }

  const hoursOptions = Array.from({length: 24}, (_, i) => i)
  const minutesOptions = Array.from({length: 60}, (_, i) => i)
  // const isRestricted = gig.hours < 8 && totalHours === 8
  const isRestricted = parseFloat(gig?.hours).toFixed(2) === workTime && totalMinutes === 0

  const calculateTotalMinutes = (hours, minutes) => {
    if (hours === '' || minutes === '') {
      return '0.00'
    }
    return hours * 60 + minutes
  }
  const calculateDecimalTime = (hours, minutes) => {
    if (hours === '' || minutes === '') {
      return '0.00'
    }
    return (hours + minutes / 60).toFixed(2)
  }

  useEffect(() => {
    // calculate expected gig fee
    if (gig) {
      setLimit(hoursLimit())
      const calculatedWorktime = calculateDecimalTime(totalHours, totalMinutes)
      const calculatedLate = calculateDecimalTime(lateHours, lateMinutes)
      const calculatedExtension = calculateDecimalTime(extensionHours, extensionMinutes)
      const calculatedNightSurge = calculateDecimalTime(nightSurgeHours, nightSurgeMinutes)

      setWorkTime(calculatedWorktime)
      setLate(calculatedLate)
      setExtension(calculatedExtension)
      setNightSurge(calculatedNightSurge)

      const convertedLate = calculateTotalMinutes(lateHours, lateMinutes)

      if (!isEmpty()) {
        setIsNotComplete(false)
      }

      const {jobsterFinal} = endShift_calculations(
        calculatedWorktime,
        gig?.fee,
        fees?.voluntaryFee,
        fees?.premiumFee,
        fees?.holidaySurge,
        convertedLate,
        calculatedExtension,
        calculatedNightSurge
      )
      setExpectedGigFee(jobsterFinal)
    }
  }, [
    totalHours,
    totalMinutes,
    lateHours,
    lateMinutes,
    extensionHours,
    extensionMinutes,
    nightSurgeHours,
    nightSurgeMinutes
  ])

  useEffect(() => {
    // convert initial value of gig
    if (fees) {
      const total = parseTime(parseFloat(gig.hours || 0))
      const nightSurge = parseTime(parseFloat(gig.fees.proposedNightSurgeHr || 0))
      setTotalHours(total.hours)
      setTotalMinutes(total.minutes)
      setNightSurgeHours(nightSurge.hours)
      setNightSurgeMinutes(nightSurge.minutes)
    }
  }, [fees])

  return (
    <>
      <Dialog open={open} onClose={() => handleCloseDialog()}>
        <Button sx={{ml: 'auto', pt: 2}} onClick={handleCloseDialog}>
          <Icon icon={closeIcon} width={32} height={32} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{textAlign: 'center'}}>
          <Typography variant="h4" component="span">
            Confirm End-Shift
          </Typography>
        </DialogTitle>
        <DialogActions sx={{display: 'block', pb: 5, px: 3}}>
          <Typography variant="body2" sx={{mb: 3}} component="span">
            Please ensure that all the assigned tasks has been successfully carried out. Take note of your personal
            belongings before you leave. Talk to the client/principal and indicate your final agreed rate and number of
            hours. Gig posted rates and hour is P{parseFloat(fees?.jobsterTotal).toFixed(2)} per hour for {gig.hours}{' '}
            {gig.hours && JSON.parse(gig.hours).length > 1 ? 'hour' : 'hours'} If successful, press “end-shift”
          </Typography>
          {/* <Stack direction="column" alignItems="center" sx={{mt: 4, mb: 2}}>
            <TextField
              label="Total manhours (ex. 6.50 = 6 hr and 30 min)"
              fullWidth
              type="number"
              defaultValue={gig.hours}
              sx={{
                width: '100%',
                mt: 2,
                '& .MuiInputBase-root': {
                  // backgroundColor: 'lightgray',
                  // color: 'red', // Optional
                  '& fieldset': {
                    // Target the fieldset for border
                    borderColor: workTime === '0.00' ? 'red' : 'gray'
                  }
                }
              }}
              onChange={(e) => formatDecimal(e.target.value, 'hours')}
            />
            {/* <Typography
              component="p"
              sx={{py: 1, textAlign: 'left', display: 'block', width: '100%'}}
              variant="caption"
            >
              (ex. <b>6.50</b> = 6 hours and 30 minutes)
            </Typography> */}
          {/* <TextField
              key="late"
              label="Late (no. of minutes)"
              type="number"
              defaultValue={parseFloat(0.0).toFixed(2)}
              sx={{
                width: '100%',
                mt: 2,
                '& .MuiInputBase-root': {
                  // backgroundColor: 'lightgray',
                  // color: 'red', // Optional
                  '& fieldset': {
                    borderColor: late === '0.00' ? 'red' : 'black'
                  }
                }
              }}
              onChange={(e) => formatDecimal(e.target.value, 'late')}
            />
            <TextField
              key="extension"
              label="Gig extension (no. of hours)"
              type="number"
              defaultValue={parseFloat(fees?.proposedExtensionHr || 0.0).toFixed(2)}
              sx={{
                width: '100%',
                mt: 2,
                '& .MuiInputBase-root': {
                  // backgroundColor: 'lightgray',
                  // color: 'red', // Optional
                  '& fieldset': {
                    borderColor: extension === '0.00' ? 'red' : 'gray'
                  }
                }
              }}
              onChange={(e) => formatDecimal(e.target.value, 'extension')}
            />
            <TextField
              key="night-surge"
              label="Night Surge (no. of hours)"
              type="number"
              defaultValue={parseFloat(fees?.proposedNightSurgeHr || 0.0).toFixed(2)}
              sx={{
                width: '100%',
                mt: 2,
                mb: 2,
                '& .MuiInputBase-root': {
                  // backgroundColor: 'lightgray',
                  // color: 'red', // Optional
                  '& fieldset': {
                    borderColor: fees?.proposedNightSurgeHr === '0' && nightSurge === '0' ? 'red' : 'gray'
                  }
                }
              }}
              onChange={(e) => formatDecimal(e.target.value, 'nightSurge')}
            />
          </Stack> */}
          <Grid container direction="column" spacing={2} sx={{pl: '0 !important', mt: 4, mb: 2}}>
            <Grid item container spacing={1} alignItems="center">
              <Grid item xs={4} sx={{pl: '0 !important'}}>
                <Typography>Total Manhours:</Typography>
              </Grid>
              <Grid item xs={3.5}>
                {console.log(limit, ' limit')}
                <FormControl fullWidth>
                  <InputLabel>(hours)</InputLabel>
                  <Select
                    value={totalHours}
                    onChange={(e) => handleHoursChange(e, setTotalHours)}
                    label="Total manhours (hours)"
                  >
                    {[...Array(limit).keys()].map((hour) => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3.5}>
                <FormControl fullWidth>
                  <InputLabel>(minutes)</InputLabel>
                  <Select
                    value={totalMinutes}
                    // value={totalMinutes ? totalMinutes : ''}
                    onChange={(e) => handleMinutesChange(e, setTotalMinutes)}
                    label="Total manhours (minutes)"
                  >
                    {isRestricted ? (
                      <MenuItem key={0} value={0}>
                        0
                      </MenuItem>
                    ) : (
                      [0, 30].map((minute) => (
                        <MenuItem key={minute} value={minute}>
                          {minute}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container spacing={1} alignItems="center">
              <Grid item xs={4} sx={{pl: '0 !important'}}>
                <Typography>Late:</Typography>
              </Grid>
              <Grid item xs={3.5}>
                <FormControl fullWidth>
                  <InputLabel>(hours)</InputLabel>
                  <Select value={lateHours} onChange={(e) => handleHoursChange(e, setLateHours)} label="Late (hours)">
                    {hoursOptions.map((hour) => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3.5}>
                <FormControl fullWidth>
                  <InputLabel>(minutes)</InputLabel>
                  <Select
                    value={lateMinutes}
                    onChange={(e) => handleMinutesChange(e, setLateMinutes)}
                    label="Late (minutes)"
                  >
                    {minutesOptions.map((minute) => (
                      <MenuItem key={minute} value={minute}>
                        {minute}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container spacing={1} alignItems="center">
              <Grid item xs={4} sx={{pl: '0 !important'}}>
                <Typography>Gig Extension:</Typography>
              </Grid>
              <Grid item xs={3.5}>
                <FormControl fullWidth>
                  <InputLabel>(hours)</InputLabel>
                  <Select
                    value={extensionHours}
                    onChange={(e) => handleHoursChange(e, setExtensionHours)}
                    label="Gig extension (hours)"
                  >
                    {hoursOptions.map((hour) => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3.5}>
                <FormControl fullWidth>
                  <InputLabel>(minutes)</InputLabel>
                  <Select
                    value={extensionMinutes}
                    onChange={(e) => handleMinutesChange(e, setExtensionMinutes)}
                    label="Gig extension (minutes)"
                  >
                    {[0, 30].map((minute) => (
                      <MenuItem key={minute} value={minute}>
                        {minute}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid item container spacing={1} alignItems="center">
              <Grid item xs={4} sx={{pl: '0 !important'}}>
                <Typography>Night Surge:</Typography>
              </Grid>
              <Grid item xs={3.5}>
                <FormControl fullWidth>
                  <InputLabel>(hours)</InputLabel>
                  <Select
                    value={nightSurgeHours}
                    onChange={(e) => handleHoursChange(e, setNightSurgeHours)}
                    label="Night Surge (hours)"
                  >
                    {[...Array(9).keys()].map((hour) => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3.5}>
                <FormControl fullWidth>
                  <InputLabel>(minutes)</InputLabel>
                  <Select
                    value={nightSurgeMinutes}
                    onChange={(e) => handleMinutesChange(e, setNightSurgeMinutes)}
                    label="Night Surge (minutes)"
                    defaultValue=""
                  >
                    {[0].map((minute) => (
                      <MenuItem key={minute} value={minute}>
                        {minute}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {/* Adding Total Gig Fee */}
            <Grid item container spacing={1} alignItems="center" sx={{pl: 0}}>
              <Grid item xs={4} sx={{pl: '0 !important'}}>
                <Typography>Expected Gig Fee:</Typography>
              </Grid>
              <Grid item xs={8} sx={{pl: 0}}>
                <Typography sx={{color: 'starjobs.main'}}>
                  Php {expectedGigFee ? expectedGigFee.toFixed(2) : '0.00'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Stack sx={{my: 2}}>
            <LoadingButton
              color="primary"
              size="large"
              variant="contained"
              onClick={() => setIsFinal(true)}
              loading={loading}
              disabled={isNotComplete}
            >
              Confirm End Shift
            </LoadingButton>
            <LoadingButton onClick={handleCloseDialog} size="large" variant="outlined" color="inherit" sx={{mt: 2}}>
              No
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
      {/* Final confirm dialog */}
      {/* <Dialog open={isFinal} onClick={() => setIsFinal(false)}> */}
      <Dialog open={isFinal}>
        <Button
          sx={{ml: 'auto', pt: 2}}
          onClick={() => {
            setIsFinal(false)
            setChecked(false)
          }}
        >
          <Icon icon={closeIcon} width={32} height={32} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{textAlign: 'center'}}>
          <Typography variant="h4" sx={{maxWidth: '75%', mx: 'auto'}}>
            Do you confirm to end this Gig?
          </Typography>
        </DialogTitle>
        <DialogActions sx={{display: 'block', pb: 5, px: 3}}>
          <Typography variant="h6" sx={{mb: 6, fontWeight: 400, textAlign: 'center', mx: 'auto'}}>
            Please ensure all tasks are completed accurately and efficiently. Ensure proper endorsement and turnover
            before confirming the successful completion of the gig.
            <strong> Confirm only if everything is in order.</strong>
            <Divider sx={{mb: 1, mt: 2, fontWeight: 400, backgroundColor: 'rgba(0, 0, 0, 0.87)'}} />
            Once confirmed,<strong>time records cannot be adjusted and gig fees will be released.</strong> Please ensure
            that all details are correct before confirming.
          </Typography>
          <Box sx={{display: 'inline', width: {xs: '50%', sm: '33%', lg: '33%'}}}>
            <FormControlLabel
              sx={{marginTop: '0 !important'}}
              control={
                <Checkbox
                  id="skills"
                  color="primary"
                  checked={checked}
                  onChange={(e) => handleChange(e)}
                  // value={checked}
                />
              }
              label={
                <Typography variant="body2" align="left" sx={{color: 'text.secondary', marginTop: '0 !important'}}>
                  I confirm that the time records are correct
                </Typography>
              }
            />
          </Box>
          <Stack sx={{my: 2}}>
            <LoadingButton
              color="primary"
              size="large"
              variant="contained"
              onClick={() => handleCommit(gig)}
              loading={loading}
              disabled={!checked}
              sx={{textTransform: 'initial !important'}}
            >
              Yes, I have informed the client
            </LoadingButton>
            <LoadingButton
              onClick={() => {
                setIsFinal(false)
                setChecked(false)
              }}
              size="large"
              variant="outlined"
              color="inherit"
              sx={{mt: 2}}
            >
              Cancel
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  )
}

EndShiftNotification.propTypes = {
  open: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  gig: PropTypes.array,
  onCommit: PropTypes.func,
  handleClose: PropTypes.func,
  onReject: PropTypes.func,
  loading: PropTypes.bool
}

export default EndShiftNotification
