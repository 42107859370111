import React, {useEffect, useState} from 'react'
import {useSnackbar} from 'notistack'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'

const NetworkAlert = () => {
  const [isSlowConnection, setIsSlowConnection] = useState(false)
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  useEffect(() => {
    const checkNetworkSpeed = () => {
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
      if (connection) {
        const {downlink} = connection
        if (downlink < 0.5) {
          setIsSlowConnection(true)
          //   enqueueSnackbar('Slow internet connection detected. Please wait...', {
          //     variant: 'warning',
          //     persist: true,
          //   });
        } else {
          setIsSlowConnection(false)
          closeSnackbar()
        }
      }
    }

    checkNetworkSpeed()
  }, [])

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 60,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        zIndex: 9999,
        fontSize: '12px'
      }}
    >
      {isSlowConnection && (
        <Alert severity="warning" sx={{fontSize: '0.875rem', whiteSpace: 'nowrap'}}>
          Slow internet connection detected. Please check your wifi/data
        </Alert>
      )}
    </Box>
  )
}

export default NetworkAlert
