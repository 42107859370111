import {create} from 'apisauce'
import storage from './storage'
import config from 'utils/config'

const base_url = config.apiUrlBackup
const apiClientBackup = create({
  baseURL: base_url
})

apiClientBackup.addAsyncRequestTransform(async (request) => {
  const authToken = await storage.getToken()
  if (!authToken) return
  request.headers['Authorization'] = `Bearer ${authToken}`
})

export default apiClientBackup
