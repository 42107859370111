const config = {
  apiUrl: process.env.GATSBY_API_URL,
  apiUrlBackup: process.env.GATSBY_API_URL_BACKUP,

  socketUrl: process.env.GATSBY_SOCKET_URL,
  clientUrl: process.env.GATSBY_CLIENT_URL,

  discord: {
    url: process.env.GATSBY_DISCORD_URL,
    key: process.env.GATSBY_DISCORD_PROD_KEY, // Test
    //key: process.env.DISCORD_TEST_KEY, // Prod
    registrationKey: process.env.GATSBY_DISCORD_REGISTRATION_KEY,
    endShiftKey: process.env.GATSBY_DISCORD_END_SHIFT_KEY
  },
  aws: {
    s3UploadUrl: process.env.GATSBY_AWS_S3UPLOAD_URL
  },
  browser: {
    vapid_key: process.env.GATSBY_BROWSER_VAPID_KEY
  },
  pusher: {
    cluster: process.env.GATSBY_PUSHER_CLUSTER,
    key: process.env.GATSBY_PUSHER_KEY,
    instanceId: process.env.GATSBY_PUSHER_INSTANCE_ID
  },
  userSnap: {
    apiKey: process.env.GATSBY_USERSNAP_API_KEY,
    globalKey: process.env.GATSBY_USERSNAP_GLOBAL_KEY
  },
  fallback: {
    querystring: require.resolve('querystring-es3')
  },
  firebase: {
    apiKey: process.env.GATSBY_FIREBASE_API_KEY,
    authDomain: process.env.GATSBY_AUTH_DOMAIN,
    projectId: process.env.GATSBY_PROJECT_ID,
    storageBucket: process.env.GATSBY_STORAGE_BUCKET,
    messagingSenderId: process.env.GATSBY_MESSAGING_SENDER_ID,
    appId: process.env.GATSBY_APP_ID,
    measurementId: process.env.GATSBY_MEASUREMENT_ID
  },
  facebook: {
    get_user_uri: process.env.GATSBY_FACEBOOK_USER_URI
  }
}

export default config
