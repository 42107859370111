import React, {useEffect, useState, createContext, useContext} from 'react'
import {Dialog, DialogContent, DialogTitle, DialogActions, Button, Typography, Stack} from '@mui/material'

import PropTypes from 'prop-types'
import {Icon} from '@iconify/react'
import closeIcon from '@iconify/icons-eva/close-circle-outline'

import {useAuth} from './AuthContext'

ComponentProvider.propTypes = {
  children: PropTypes.node
}

const staticDescription = {
  CLIENT:
    'Welcome to Starjobs:! The First On-Demand Skills and Talents Services Application. Experience flexibility, best performance and labor cost efficiency today. Start completing your profile and be the first to get thousands of JOBSTERS offering gig services.',
  JOBSTER:
    'Welcome to Starjobs:! The First On-Demand Skills and Talents Services Application. Start completing your profile to get CLIENTS  and Flexibility, Freedom, Immediate additional income enjoy today.'
}

const ComponentContext = createContext({})

export function ComponentProvider({children}) {
  const {currentUser, sessionUser} = useAuth()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [completeProfile, setCompleteProfile] = useState(false)
  const [description, setDescription] = useState(undefined)

  const load = async () => {
    setLoading(true)

    try {
      await sessionUser()

      if (Object.keys(currentUser).length === 0) return false
      if (currentUser.isActive) return false

      setCompleteProfile(true)

      if (currentUser.accountType === 1) {
        setDescription(staticDescription['CLIENT'])
        return setOpen(true)
      } else {
        setDescription(staticDescription['JOBSTER'])
        return setOpen(true)
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    load()
    // eslint-disable-next-line
  }, [])

  const onClose = async () => {
    setLoading(true)
    try {
      setOpen(false)
    } finally {
      setLoading(false)
    }
  }

  const onConfirm = async () => {
    setLoading(true)
    try {
      setOpen(false)
    } finally {
      setLoading(false)
    }
  }

  return (
    <ComponentContext.Provider value={{}}>
      {children}
      <Dialog open={open}>
        <Button sx={{ml: 'auto', p: 2}} onClick={onClose}>
          <Icon icon={closeIcon} width={32} height={32} color="#b2b2b2" />
        </Button>
        <DialogTitle sx={{textAlign: 'center', pb: 0, mb: 2}}>
          <Typography variant="h3" component="span">
            Greetings!
          </Typography>
        </DialogTitle>
        <DialogContent sx={{mb: 2}}>
          <Typography variant="body1" sx={{textAlign: 'center', fontWeight: 400}}>
            {description}
          </Typography>
        </DialogContent>
        <DialogActions sx={{display: 'block', pb: 5, px: 5, mb: 2}}>
          <Stack>
            <Button color="primary" variant="contained" onClick={onConfirm} disabled={loading}>
              Close
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </ComponentContext.Provider>
  )
}

export const useComponent = () => {
  return useContext(ComponentContext)
}
