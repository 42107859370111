//

// contexts/GigsContext.js

// import React, {createContext, useEffect, useState} from 'react'
// import gigs_api from 'libs/endpoints/gigs'
// import moment from 'moment'
// const GigsContext = createContext()

// const GigsProvider = ({children}) => {
//   const [gigs, setGigs] = useState([])
//   const [isLoading, setLoading] = useState(false)
//   const [page, setPage] = useState(1);
//   const [limit] = useState(10);

//   useEffect(() => {
//     const loadGigs = async () => {
//       setLoading(true)
//       try {
//         const result = await gigs_api.get_gigs_no_category(page, limit)
//         if (!result.ok) {
//           return
//         }
//         const {data} = result
//         if (!data || data.length === 0) {
//           setGigs([])
//           return
//         }
//         // data.sort((a, b) => (moment(a.date + ' ' + a.time) > moment(b.date + ' ' + b.time) ? 1 : -1))
//         // const filteredData = data.filter((obj) => (moment(obj.from).isValid() ? obj : ''))
//         setGigs(data)
//         setLoading(false)
//       } finally {
//         setLoading(false)
//       }
//     }
//     loadGigs()
//   }, [])

//   return <GigsContext.Provider value={{gigs, setGigs, isLoading}}>{children}</GigsContext.Provider>
// }

// export {GigsProvider, GigsContext}

import React, {createContext, useEffect, useState} from 'react'
import gigs_api from 'libs/endpoints/gigs'
import moment from 'moment'

const GigsContext = createContext()

const GigsProvider = ({children}) => {
  const [gigs, setGigs] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [limit] = useState(10)

  useEffect(() => {
    const loadGigs = async () => {
      setLoading(true)
      try {
        const result = await gigs_api.get_gigs_no_category(page, limit)
        if (!result.ok) {
          return
        }
        const {gigs: data, page: currentPage, limit: currentLimit} = result.data
        if (!data || data.length === 0) {
          setGigs([])
          return
        }

        // Optional: Sort data by `from` date
        data.sort((a, b) => (moment(a.from).isAfter(moment(b.from)) ? 1 : -1))

        setGigs(data)
        setPage(currentPage)
        setLoading(false)
      } catch (error) {
        console.error('Error loading gigs:', error)
        setLoading(false)
      }
    }
    loadGigs()
  }, [page, limit])

  return <GigsContext.Provider value={{gigs, setGigs, isLoading, page, setPage}}>{children}</GigsContext.Provider>
}

export {GigsProvider, GigsContext}
