import {create} from 'apisauce'
import storage from './storage'
import config from 'utils/config'

const base_url = config.apiUrl
const apiClient = create({
  baseURL: base_url
})

apiClient.addAsyncRequestTransform(async (request) => {
  const authToken = await storage.getToken()
  if (!authToken) return
  request.headers['Authorization'] = `Bearer ${authToken}`
})

export default apiClient
