// GlobalStatesContext.js

import React from 'react'
import {GigsProvider} from 'contexts/GigsContext'
import {JobsterProvider} from 'contexts/JobsterContext'

import {FirebaseProvider} from 'contexts/FirebaseContext'
import {AuthProvider} from 'contexts/AuthContext'
import {SessionProvider} from 'contexts/SessionContext'
import {ComponentProvider} from 'contexts/ComponentContext'
import {NotificationsProvider} from 'contexts/NotificationContext'
import {RatingsProvider} from 'contexts/RatingContext'
import NotistackProvider from 'components/NotistackProvider'

const GlobalStatesContext = ({children}) => {
  return (
    <FirebaseProvider>
      <AuthProvider>
        <SessionProvider>
          <ComponentProvider>
            <NotificationsProvider>
              <NotistackProvider>
                <RatingsProvider>
                  <GigsProvider>
                    <JobsterProvider>{children}</JobsterProvider>
                  </GigsProvider>
                </RatingsProvider>
              </NotistackProvider>
            </NotificationsProvider>
          </ComponentProvider>
        </SessionProvider>
      </AuthProvider>
    </FirebaseProvider>
  )
}

export default GlobalStatesContext
