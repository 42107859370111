import React, {useEffect, useState, Suspense} from 'react'
import {Box, Button, Modal, Typography} from '@mui/material'
import {Router, Location, useLocation} from '@reach/router'

// import 'utils/highlight'
import {TransitionGroup, CSSTransition} from 'react-transition-group'

// routes
// import AppRoute from 'routes'

// theme
import ThemeConfig from 'theme/themeConfig'
// components
import Logo from 'components/Logo'
import ThemePrimaryColor from 'components/ThemePrimaryColor'

import {GenericNotification} from 'components/notifications'
import NetworkAlert from 'components/NetworkAlert'
import UpdatePopup from 'components/UpdatePopup'

import {SettingsProvider} from 'contexts/SettingsContext'
import {CollapseDrawerProvider} from 'contexts/DrawerContext'
import GlobalStatesProvider from 'contexts/GlobalStatesContext'
import settings_api from 'libs/endpoints/settings'

import ls from 'local-storage'

// components
// import PrivateRoute from 'components/PrivateRoute'

const LoginPage = React.lazy(() => import('screens/Login'))
const SocialLoginPage = React.lazy(() => import('screens/SocialSelectAccount'))
const VerificationPage = React.lazy(() => import('screens/Verification'))
const Registration = React.lazy(() => import('screens/Registration'))
const ForgotPassword = React.lazy(() => import('screens/ForgotPassword'))
const ResetPassword = React.lazy(() => import('screens/ResetPassword'))
const DashboardPage = React.lazy(() => import('screens/Dashboard'))
const DemoPage = React.lazy(() => import('screens/Demo'))
const Welcome = React.lazy(() => import('screens/Welcome'))

const JobsterHome = React.lazy(() => import('screens/jobster/home'))
const JobsterDashboard = React.lazy(() => import('screens/jobster/dashboard'))
const JobsterProfile = React.lazy(() => import('screens/jobster/profile'))
const JobsterEditProfile = React.lazy(() => import('screens/jobster/profile/editProfile'))
const JobsterOnboard = React.lazy(() => import('screens/jobster/onboard'))
const JobsterOnboardSuccess = React.lazy(() => import('screens/jobster/onboard/success'))
const PendingVerification = React.lazy(() => import('screens/jobster/profile/pendingVerification'))
const RejectedVerification = React.lazy(() => import('screens/jobster/profile/rejectedVerification'))
const JobsterContractDetails = React.lazy(() => import('screens/jobster/contract'))
const BrowseCategory = React.lazy(() => import('screens/BrowseCategory'))

const ClientHome = React.lazy(() => import('screens/client/home'))
const ClientProfile = React.lazy(() => import('screens/client/profile'))
const ClientEditProfile = React.lazy(() => import('screens/client/profile/editProfile'))
const ClientCreateGig = React.lazy(() => import('screens/client/posting'))
const ClientApplicants = React.lazy(() => import('screens/client/applicants'))
const ClientApplicantProfile = React.lazy(() => import('screens/client/applicantProfile'))
const ClientEditDocument = React.lazy(() => import('screens/ClientEditDocument'))
const JobsterEditRequirements = React.lazy(() => import('screens/JobsterEditRequirement'))

const ClientJobsterProfile = React.lazy(() => import('screens/jobster/profile'))
const ClientOnboard = React.lazy(() => import('screens/client/onboard'))
const ClientOnboardSuccess = React.lazy(() => import('screens/client/onboard/success'))
const ClientPublicProfile = React.lazy(() => import('screens/client/publicProfile'))

const ChangePassword = React.lazy(() => import('screens/ChangePassword'))
const NotificationSetting = React.lazy(() => import('screens/NotificationSetting'))
const EwalletSetting = React.lazy(() => import('screens/EwalletSetting'))
const AccountSetting = React.lazy(() => import('screens/AccountSetting'))
const Message = React.lazy(() => import('screens/Message'))
const MessageDetails = React.lazy(() => import('screens/MessageDetails'))

const Gigs = React.lazy(() => import('screens/gigs'))
const GigDetails = React.lazy(() => import('screens/gigs/details'))
const GigContractDetails = React.lazy(() => import('screens/gigs/contracts'))
const GigContractTransactions = React.lazy(() => import('screens/gigs/contracts/transactions'))
const GigsFullDetails = React.lazy(() => import('screens/gigs/fullDetails'))
const GigApplySuccess = React.lazy(() => import('screens/gigs/success'))
const GigEdit = React.lazy(() => import('screens/gigs/edit'))
import '../style.css'

const SplashScreen = () => {
  return (
    <Box sx={{minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Logo />
    </Box>
  )
}

const Application = () => {
  const [updateAvailable, setUpdateAvailable] = useState(false)
  const location = useLocation()

  useEffect(() => {
    const check_app_version = async () => {
      const check_settings = await settings_api.get_settings()
      const new_version = check_settings.data.appVersion

      console.log('🚀 ~ constcheck_app_version= ~ new_version:', new_version)
      if (!check_settings.ok) return

      const current_version = ls.get('appversion')
      console.log('🚀 ~ constcheck_app_version= ~ current_version:', current_version)

      if (!current_version || current_version === 'undefined' || current_version === undefined) {
        ls.set('appversion', new_version)
      } else {
        if (new_version !== current_version) {
          ls.set('appversion', new_version)
          console.log('Version not same')
          setUpdateAvailable(true)
        }
      }
    }
    check_app_version()
  }, [location.pathname])

  return (
    <SettingsProvider>
      <CollapseDrawerProvider>
        <ThemeConfig>
          <ThemePrimaryColor>
            <Location>
              {() => (
                <GlobalStatesProvider>
                  <TransitionGroup className="transition-group">
                    <CSSTransition key={location.key} classNames="fade" timeout={300}>
                      <Suspense fallback={SplashScreen()}>
                        <NetworkAlert />
                        <UpdatePopup open={updateAvailable} />
                        <Router>
                          {/* add page routes here */}
                          <LoginPage path="/login" />
                          <LoginPage path="/" />
                          <SocialLoginPage path="/social" />
                          <VerificationPage path="/verification" />
                          <Registration path="/sign-up" />
                          <ForgotPassword path="/forgot-password" />
                          <ResetPassword path="/reset-password" />
                          <Welcome path="/setup" />
                          <DashboardPage path="/dashboard" />
                          <BrowseCategory path="/search" />
                          {/* jobsters */}
                          <JobsterHome path="/freelancer/app" />
                          <JobsterDashboard path="/freelancer/dashboard" />
                          <JobsterProfile path="/freelancer/profile" />
                          <JobsterEditProfile path="/freelancer/edit" />
                          <JobsterEditRequirements path="/freelancer/edit/requirements" />
                          <JobsterOnboard path="/freelancer/onboard" />
                          <JobsterOnboardSuccess path="/freelancer/onboard/success" />
                          <PendingVerification path="freelancer/profile/pendingVerification" />
                          <RejectedVerification path="freelancer/profile/rejectedVerification" />
                          <JobsterContractDetails path="/freelancer/contract/details/:id" />
                          <ClientHome path="/client/app" />
                          <ClientProfile path="/client/profile" />
                          <ClientPublicProfile path="/client/jobster/:id" />
                          <ClientEditProfile path="/client/edit" />
                          <ClientEditDocument path="/client/edit/document" />
                          <ClientOnboard path="/client/onboard" />
                          <ClientOnboardSuccess path="/client/onboard/success" />
                          <ClientCreateGig path="/client/gig/create" />
                          <ClientApplicants path="/client/gigs/applicants/:id" />
                          <ClientApplicantProfile path="/client/gigs/applicant/profile/:id/:gig_id" />
                          <ClientJobsterProfile path="/client/jobster/:id" />
                          {/* accounts */}
                          <Message path="/freelancer/message" />
                          <Message path="/client/message" />
                          <MessageDetails path="/notification/details/:id/:hid/:title" />
                          <ChangePassword path="/account/change-password" />
                          <NotificationSetting path="/account/notification-setting" />
                          <AccountSetting path="/account/account-setting" />
                          <EwalletSetting path="/account/ewallet-setting" />
                          {/* gigs */}
                          <Gigs path="/gigs" />
                          <Gigs path="/gigs/:category" />
                          <GigsFullDetails path="/gigs/det/:id" />
                          <GigDetails path="/gigs/details/:id/:category" />
                          <GigContractDetails path="/gigs/details/contract/:id" />
                          <GigContractTransactions path="/gigs/details/contract/sales/:id" />
                          <GigEdit path="/gigs/edit/:id" />
                          <GigApplySuccess path="/gigs/apply/success" />
                          <BrowseCategory path="/freelancer/search" />
                          <BrowseCategory path="/client/search" />
                          {/* Demo */}
                          <DemoPage path="/demo" />
                        </Router>
                      </Suspense>
                    </CSSTransition>
                  </TransitionGroup>
                </GlobalStatesProvider>
              )}
            </Location>
          </ThemePrimaryColor>
        </ThemeConfig>
      </CollapseDrawerProvider>
    </SettingsProvider>
  )
}
const App = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    let mounted = true

    if (mounted)
      setTimeout(() => {
        setLoaded(true)
      }, 1000)

    return () => {
      mounted = false
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (!navigator.onLine) {
        alert('No internet, Please check your network connection ', {variant: 'warning'})
      }
    }, 1000) // Run every second
    return () => {
      clearInterval(interval) // Cleanup by clearing the interval when the component unmounts
    }
  }, [])

  return <>{loaded ? <Application /> : <SplashScreen />}</>
  // return <Application />
}

export default App
