import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'

import {useNavigate} from '@reach/router'

import {styled} from '@mui/system'

const StyledDialogTitle = styled(DialogTitle)({
  position: 'relative'
})

const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  right: 8,
  top: -300,
  color: (theme) => theme.palette.grey[500]
})

export default function JobsterNotice({open, handleClose}) {
  const navigate = useNavigate()

  const handleConfirm = () => {
    console.log('Navigating to /freelancer/dashboard/?tab=1 ')
    navigate('/freelancer/dashboard')
    window.location.reload()
  }
  const handleBackdropClick = (event) => {
    console.log(event)
    event.stopPropagation()
  }
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Notice to Jobsters</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please inform the client to confirm the end shift within 10 minutes. If the end shift is not confirmed within
          this time, the client will manage the gig fee settlement.
          <br />
          <br />
          <Typography variant="body2" sx={{fontStyle: 'italic', fontSize: '0.875rem'}}>
            Pakitiyak na ma-kumpirma ang pagtatapos ng shift ng jobster sa loob ng 10 minuto. Kung hindi makumpirma ang
            pagtatapos ng shift sa loob ng oras na ito, ang kliyente ang mamahala sa pag-aayos ng bayad sa gig.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color="primary">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}
JobsterNotice.PropTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func
}
